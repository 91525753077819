<template>
  <div class="view pa24">
    <p class="mb10">成员权限比例设置</p>
    <!-- 员工分销比例
    <el-input
      class="w100"
      v-model="royaltyRatio"
      @input="(e) => (royaltyRatio = e.slice(0, 3))"
      type="number"
    />
    % -->
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" prop="lavelName" label="等级名称">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.edit == true"
              type="text"
              v-model="scope.row.lavelName"
              size="small"
            >
            </el-input>
            <div v-else>{{ scope.row.lavelName }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="oneRoyalty" label="佣金比例">
          <template slot-scope="scope">
            <div v-if="scope.row.edit == true">
              <el-input
              type="text"
              oninput="value=value.replace(/[^0-9.]/g,'')"

              v-model="scope.row.oneRoyalty"
              style="width: 100px"
              @change="oneRoyaltyChange(scope.row)"
              size="small"
            ></el-input
            >%
            </div>
            <div v-else>{{ scope.row.oneRoyalty }}%</div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="twoRoyalty" label="二级佣金比例">
          <template slot-scope="scope">
            <div v-if="scope.row.edit == true">
              <el-input
              type="text"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              v-model="scope.row.twoRoyalty"
              style="width: 100px"
              @change="oneRoyaltyChange(scope.row)"
              size="small"
            ></el-input
            >%
            </div>
            <div v-else>{{ scope.row.twoRoyalty }}%</div>
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="conditions" label="升级条件">
          <template slot-scope="scope">
            <div v-if="scope.row.edit == true">
              分销订单金额满<el-input
                type="text"
                style="width: 80px"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                :disabled="scope.row.level == 1"
                v-model="scope.row.conditions"
                size="small"
              ></el-input
              >元
            </div>
            <div v-else>分销订单金额满{{ scope.row.conditions }}元</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="170">
          <template slot-scope="scope">
            <el-button type="text" @click="editRow(scope.row)" size="small">
              {{ scope.row.edit ? "确定" : "编辑" }}
            </el-button>
            <el-button type="text" size="small" @click="cleanRow(scope.row)">
              清空
            </el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <div class="d-flex justify-content-center">
      <el-button type="primary" @click="submitData"> 保存 </el-button>
    </div>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryCompanyRoyalty, updateCompanyRoyalty } from "@/api/distribution";
export default {
  name: "distributionLevel",
  components: {
    commonTable,
  },
  data() {
    return {
      num: 1,
      royaltyRatio: "",
      tableData: [
              {level:1,lavelName:'初级分销员',oneRoyalty:0,twoRoyalty:0,conditions:0},
              {level:2,lavelName:'中级分销员',oneRoyalty:0,twoRoyalty:0,conditions:0},
              {level:3,lavelName:'高级分销员',oneRoyalty:0,twoRoyalty:0,conditions:0}
            ],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  created() {
    this.queryCompanyRoyalty();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    async queryCompanyRoyalty() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      };
      try {
        this.loading = true;
        const result = await queryCompanyRoyalty(data);
        this.loading = false;
        this.tableData = result.data ? result.data : this.tableData;
        this.total = result.data.length;
        this.royaltyRatio = this.tableData[0].royaltyRatio
      } catch (error) {
        this.loading = false;
      }
    },
    oneRoyaltyChange(row) {
      row.oneRoyalty = parseInt(row.oneRoyalty).toFixed(0)
      if (parseInt(row.oneRoyalty) + parseInt(row.twoRoyalty) > 100) {
        row.oneRoyalty = parseInt(row.oneRoyalty).toFixed(0)
        this.$message.error("设置比例不能超过100%");
        row.oneRoyalty = 0;
        return;
      }
    },
    twoRoyaltyChange(row) {
      row.twoRoyalty = parseInt(row.twoRoyalty).toFixed(0)
      console.log(row);
      if (parseInt(row.oneRoyalty) + parseInt(row.twoRoyalty) > 100) {
        row.twoRoyalty = parseInt(row.twoRoyalty).toFixed(0)
        this.$message.error("设置比例不能超过100%");
        row.twoRoyalty = 0;
        return;
      }
    },
    editRow(row) {
      if (!row.edit) {
        row.lavelName += " ";
        row.edit = true;
      } else {
        row.edit = !row.edit;
      }
      if (!row.edit) {
        row.lavelName = row.lavelName.replace(/\s+/g, "");
      }
    },
    cleanRow(row) {
      if (row.level != 1) {
        row.conditions = "";
      }
      row.oneRoyalty = "";
      row.twoRoyalty = "";
    },
   async submitData() {
      if (this.royaltyRatio > 100 || this.royaltyRatio < 0) {
        this.$message({
          message: "分销比例设置错误,必须大于0小于100",
          type: "warning",
        });
        return;
      }
      // //检查是否有没有录入的数据
      // const check = this.tableData.find((item) => {
      //   return (
      //     !item.oneRoyalty ||
      //     !item.twoRoyalty ||
      //     (!item.conditions && item.level != 1)
      //   );
      // });
      // if (check) {
      //   this.$message({ message: "等级设置不完整,请检查", type: "warning" });
      //   return;
      // }
      //二级金额不能大于三级金额
      const two = this.tableData.find((item) => {
        return item.level == 2;
      });
      const three = this.tableData.find((item) => {
        return item.level == 3;
      });
      // if (parseFloat(two.conditions) >= parseFloat(three.conditions)) {
      //   this.$message({ message: "二级金额不能大于三级金额", type: "warning" });
      //   return;
      // }
      const data = {
        royaltys: this.tableData,
        royaltyRatio: this.royaltyRatio,
      };
      try {
        await updateCompanyRoyalty(data,{ showLoading: true });
        this.queryCompanyRoyalty();
         this.$message({ message: '保存成功',type:'success' });
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
